<template>
    <div class="home">
      <!-- <input type="file" id="fileInput" @change="uploadCode($event)"> -->
      <!-- <img alt="Vue logo" src="../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <iframe
        id="iframeId"
        name="iframeId"
        ref="frame" 
        :src="url"
        style="
            width: 100%;
            height: 99vh;
        "
        frameborder="0"
        scrolling="no"
        seamless
        ></iframe>
        <div class="fixd" @click="gettotal">
            原文传递   
        </div>  
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="70%"
            :before-close="handleClose"> 
            <div class="toptrans"> 
                <div :class="active == 0?'active':''" @click="tabs(0)">原文传递</div>
                <div :class="active == 1?'active':''" @click="tabs(1)">传递记录</div> 
            </div>
            <div v-show="active==0">
                <div style="padding:0 20px;">
                    <div class="trans">
                        <div class="tranleft">
                            <div class="flex">
                                <div class="types">资讯标题：</div>
                                <div class="detail"><span class="text">{{bookDetail.book_name}}</span></div>
                            </div>
                            <div class="flex">
                                <div class="types">资讯类型：</div>
                                <div class="detail">图书</div>
                            </div>
                            <div class="flex">
                                <div class="types">本书已传递页码记录：</div>
                                <div class="detail"><span style="color:#b14a75;" v-for="(item,index) in bookDetail.all_user_page_list" :key="index">{{item}}；</span></div>
                            </div>
                        </div>
                        <div class="tranright">
                            <div>
                                <div class="flex">
                                    <div class="types">单次可传递最大页数：</div>
                                    <!-- <div class="detail">{{bookDetail.allow_single_day_page}}</div> -->
                                    <div class="detail">{{bookDetail.allow_single_time_page}}</div>
                                </div>
                                <div class="flex">
                                    <div class="types">今日可传递最大页数：</div>
                                    <!-- <div class="detail">{{bookDetail.allow_single_time_page}}</div> -->
                                    <div class="detail">{{bookDetail.allow_single_day_page}}</div>
                                </div>
                                <div class="flex">
                                    <div class="types">今日可传递剩余页数：</div>
                                    <div class="detail" style="color:red;">{{bookDetail.today_surplus}}</div>
                                </div>
                            </div>
                            <div class="tranright_right">
                                <el-progress type="circle" :percentage="percentage" :format="format" :color="customColor">
                                </el-progress>
                            </div>
                        </div>
                    </div>
                
                    <div class="intrans">
                        <div style="width:56px;margin-right: 20px;">起始页</div>
                        <ul class="istrans">
                            <el-tooltip v-for="(item,index) in forlist" :key="index"  placement="top" effect="light" :popper-class="'tooltip_popover'">
                                <div slot="content" style="color:rgb(111, 99, 207);">{{item.page}}</div>
                                <li class="transitem" :style="'width:calc(' + item.ins + '% );height:6px;background:'+item.istrans">
                                
                                </li> 
                            </el-tooltip>
                            
                        </ul>
                        <div style="width:80px;margin-left: 20px;">
                            {{ bookDetail.book_total_num }}（页）
                        </div>
                    </div>
                    <p class="title">*请输入需要咨询的页码范围</p>
                    <div class="flex" style="margin-top:10px;">
                        <div class="">资讯范围：</div>
                        <div class="detail">
                            <div>起始页<input class="isinput" type="number" v-model="start_num" />页 至<input  class="isinput" type="number" v-model="end_num" />页</div> 
                            <!-- <div style="font-size:12px;color:red;margin: 10px 0;line-height:15px;">
                                （提示：本书共{{bookDetail.book_total_num}}页，为保护版权单本书资讯量合计不得超过全书页码的2/3页数，单天的咨询量不得超过全书页码的1/3页数）
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="read">
                    服务说明：
                </div>
                <div class="readdetail">
                    <p>1.本平台尊重并维护作者和出版者的知识产权利益，请在使用传递服务时严格遵守法律法规和相关规定，并遵循合理使用的原则；</p>
                    <p>2.严禁任何个人或单位连续、系统、集中、批量地进行传递，更不能使用软件工具批量下载；</p>
                    <p>3.通过本方式所获取的文献仅供您本人用于学习研究，严禁将所获得的文献提供给非合法用户以及利用获得的文献资料进行非法牟利；</p>
                </div>
               
            </div>
            <div v-show="active==1">
                <el-table
                    v-loading="tranloading"
                    :data="translist"
                    style="width: 100%">
                    <el-table-column
                        prop="obtain_date"
                        label="传递日期"
                        min-width="180">
                    </el-table-column>
                    <el-table-column
                        label="咨询范围"
                        min-width="180">
                        <template slot-scope="scope">{{ scope.row.get_pages_start + '-' + scope.row.get_pages_end }}</template>    
                    </el-table-column>
                    <el-table-column
                        label="下载地址"
                        min-width="180">
                        <template slot-scope="scope">{{ scope.row.status == 0?'处理中': scope.row.status == 2?scope.row.download_link:'处理失败'}}</template>    
                    </el-table-column>
                </el-table>
                <div class="transpage">
                    <el-pagination
                        style="text-align:center;"
                        layout="prev, pager, next"
                        :total="transtotal"
                        :page-size="10"
                        :current-page="transpage"
                        @current-change="nextpage"
                        >
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" v-show="active==0">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submeit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  </template>
  
  <script>
import { getpdf,getbooktransmit,searchtaskid,getbooktransmitdetail } from "@/Api/pdf";
import { runInThisContext } from "vm";
  // document.oncontextmenu = new Function("event.returnValue=false");
  //   // 2.禁用鼠标选中
  //   document.onselectstart = new Function("event.returnValue=false");
  //   // 3.禁止键盘F12键
  //   document.addEventListener("keydown", function (e) {
  //     if (e.key == "F12") { 
  //       e.preventDefault(); // 如果按下键F12,阻止事件
  //     }
  //   });
  //   document.onkeydown = function(e) {
  //     e = window.event || e;
  //     var k = e.keyCode;
  //     //屏蔽ctrl+u，F12键
  //     if ((e.ctrlKey == true && k == 73) ||(e.ctrlKey == true && k == 80)) {
  //       e.keyCode = 0;
  //       e.returnValue = false;
  //       e.cancelBubble = true;
  //       return false;
  //     }
  //   }  
  
  export default {
    name: 'HomeView',
    data(){
      return{
        active:0,
        forlist:[],
        percentage:0,
        customColor:'#b14a75',
        url:"", 
        form:{},
        dialogVisible:false,
        start_num:'',
        end_num:'',
        pagetotal:'',
        bookDetail:'',
        transpage:1,
        activeNames: ['2'],
        translist:'',
        transtotal:0,
        tranloading:false,
      }
    }, 
    created(){
        console.log(this.forlist) 
        // this.getread()
        // this.form = this.$route.params 
        this.url ='https://ereader.newacademic.net?task_id=' + this.$route.query.task_id +"&user_code="+window.localStorage.getItem('code')
        this.searchtaskid()
    //   this.getCatalogue() 
    //   this.getpdf(1)
    //   if(this.$route.query.watermark){
    //     this.watermark = this.getDecode(this.$route.query.watermark)
    //   }
    },
    mounted(){
       
    },
    methods:{
        tabs(e){
            this.active = e
            this.gettotal('1')
        },
        format(percentage){
            // console.log("今日已传递页码数量\b" + this.bookDetail.allow_single_time_page - this.bookDetail.today_surplus + "/" + this.bookDetail.allow_single_time_page);
            
            var today = this.bookDetail.allow_single_day_page - this.bookDetail.today_surplus
            return "今日已传递页数\n" + today + "/" + this.bookDetail.allow_single_day_page
            
        },
        nextpage(e){
            this.transpage=e
            this.getbooktransmitdetail()
        },
        searchtaskid(){
            searchtaskid({
                // read_url:window.location.origin +'/#'+ this.$route.fullPath
                read_url:'https://qingli.newacademic.net/#'+ this.$route.fullPath
               
            }).then(res=>{
                this.form.task_id = res.data.data.task_id
                this.form.task_from = res.data.data.task_from

            })
        },
        submeit() {
            //单次页数
            var num = this.end_num - this.start_num +1
            if(Number(this.start_num)>Number(this.end_num)){
                this.$message.error('起始页大于结束页！')
            }else{
                if(this.start_num == 0 || this.end_num == 0 || this.start_num>this.bookDetail.book_total_num || this.end_num>this.bookDetail.book_total_num){
                    this.$message.error('请输入正确的咨询范围')
                }else{
                    if(num > this.bookDetail.allow_single_time_page){
                        this.$message.error('单次提交页数过多，单次最大可提交：' + this.bookDetail.allow_single_time_page + '页')
                        return
                    }else{
                        this.bookDetail.all_user_page_list.map(item=>{
                            var arr = item.split('-')
                            for(var i = Number(arr[0]);i<=Number(arr[1]);i++){
                                // console.log(i);
                                if(i>=this.start_num&&i<=this.end_num){
                                    num--
                                }
                            }
                        })
                        if(num<=this.bookDetail.today_surplus){
                            if(num<=this.bookDetail.all_surplus){
                                getpdf({
                                    task_id:this.form.task_id,
                                    page_start:this.start_num,
                                    page_end:this.end_num,
                                    table_from:this.form.task_from,
                                    // cb_pdf_split: this.start_num+'_'+this.end_num
                                }).then(res=>{
                                    if(res.data.data == true){
                                        this.$message.success('获取成功')
                                        // this.dialogVisible = false
                                        this.active = 1
                                    }
                                })
                            }else{
                                this.$message.error('超出本书可传递总量')
                            }
                        }else{
                            this.$message.error('超出今日剩余可传递量')
                        }
                    }
                }
            }
           
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        //获取在线阅读链接
        //  getread(){
        //     getread_url({
        //         task_id:this.$route.params.task_id,
        //         task_type:'online_read'
        //     }).then(res=>{
        //         console.log(res);
        //     })
        // },
        getbooktransmitdetail(){
            this.tranloading = true
            getbooktransmitdetail({
                task_id:this.form.task_id,
                size:10,
                page:this.transpage
            }).then(res=>{
                this.tranloading = false
                this.translist = res.data.data.data
                this.transtotal = res.data.data.total
            })
        },
        gettotal(e){
            getbooktransmit({
                task_id:this.form.task_id
            }).then(res=>{
                this.getbooktransmitdetail() 
                this.bookDetail  = res.data.data
                this.percentage =  (this.bookDetail.allow_single_day_page - this.bookDetail.today_surplus) / this.bookDetail.allow_single_day_page * 100
                this.forlist = []
                var forlist = []
                var nonepage = []
                this.bookDetail.all_user_page_list.map((item,index)=>{
                    var arr = item.split('-')

                    if(index !== this.bookDetail.all_user_page_list.length-1){
                        var nextarr = this.bookDetail.all_user_page_list[index+1].split('-')
                        var arr1 = Number(arr[1]) + 1
                        var arr2 = nextarr[0] - 1
                        // console.log(arr1,arr2);
                        var num1 = arr2 - arr1 + 1
                       
                        var ins1 = num1/this.bookDetail.book_total_num*100
                        nonepage.push({
                            ins:ins1,
                            page:arr1 +'-'+arr2,
                            istrans:'#F2F2F2',
                            position:2
                        })
                    }else{
                        var arr1 = Number(arr[1]) + 1
                        var arr2 = this.bookDetail.book_total_num
                        var num1 = arr2 - arr1 + 1
                        var ins1 = num1/this.bookDetail.book_total_num*100
                        nonepage.push({
                            ins:ins1,
                            page:arr1 +'-'+arr2,
                            istrans:'#F2F2F2',
                            position:2
                        })
                    }
                    
                    var num = arr[1] - arr[0] + 1
                    // console.log(num);
                    var ins = num/this.bookDetail.book_total_num*100
                    // console.log(ins);
                    forlist.push({
                        ins:ins,
                        page:item,
                        istrans:'#b14a75',
                        position:1
                    })
                })
                
                forlist.map((item,index)=>{
                    this.forlist.push(item)
                    nonepage.map((items,indexs)=>{
                        if(Number(item.page.split('-')[1])+1 == Number(items.page.split('-')[0])){
                            this.forlist.push(items)
                        }
                    })
                })
                if(this.forlist.length == 0){
                    this.forlist = [{
                        ins:100,
                        page:'1-'+this.bookDetail.book_total_num,
                        istrans:'#F2F2F2',
                        position:2
                    }]
                }
                // if(e){
                //     return
                // }else{
                //     this.dialogVisible = true
                // }
                this.dialogVisible = true
            })
        }
    }
    
  }
  </script>
   
  <style scoped>
    .fixd{
        width: 120px;
        height: 60px;
        background-color: #b14a75;
        border-radius: 50px 0 0 50px;
        position: fixed;
        bottom: 50px;
        right: 17px;
        font-size: 18px;
        font-weight: 600;
        line-height: 60px;
        text-align: center;
        text-indent: 10px;
        color: #fff;
        cursor: pointer;
    }
    .title{
        color: red;
        font-weight: 700;
    }
    .flex{
        display: flex;
        align-items: center;
        margin: 30px 0;
       
    }
    .isinput{
        width:120px;
        height: 30px;
        margin:0 5px;
        border: 1px solid #999;
        border-radius: 5px;
    }
    .types{
        font-weight: 700;
        color: #333;
    }
    .read{
        font-weight: 600;
        color: #333;
        margin: 20px 0;
    }
    .readdetail{
        font-size: 12px;
        padding: 0 20px;
    }
    .readdetail>p{
        margin: 6px 0;
    }
    .transpage{
        margin: 0 auto;
    }
    .text{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;

    }
    .trans{
        display: flex;
    }
    .trans>div{
        width: 50%;
    }
    .tranright{
        display: flex;
        justify-content: space-between;
    }
    
    .intrans{
        display: flex;
        justify-content: space-between;
        width: auto;
    }
   
    .istrans{
        width: 100%;
        display: flex;
        margin-bottom: 60px;
        padding-top: 4px;
        border-radius: 5px;
    }
    .transitem{
        position: relative;
        cursor: pointer;
    }
    .tranright_right{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .transitem>div{
        position: absolute;
        bottom: -34px;
        width: 26px;
        text-align: center;
        color: #b14a75;
    }
    .toptrans{
        display: flex;
    }
    .toptrans>div{
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        margin-right: 20px;
        padding-bottom: 5px;
    }
    .active{
        border-bottom: 3px solid #b14a75;
        font-weight: 600;
    }
    ::v-deep .el-dialog__header{
        /* background: #F7F8FA; */
    }
    ::v-deep .el-dialog__body{
        /* background: #F7F8FA; */
    }
    ::v-deep .el-dialog__footer{
        /* background: #F7F8FA; */
    }
    ::v-deep .el-dialog__body{
        /* max-height: 63vh; */
        /* overflow-y: scroll; */
    }
    ::v-deep .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text{
        width: 80%;
        font-size: 14px !important;
        text-align: center;
        left: 10%;
        line-height: 18px;
    }
    
    /* ::v-deep .el-table__cell{
        background: none;
    } */
  </style>
    <style>
        .el-popper__arrow::after {
            background: #f00 !important; /* 设置三角形的背景色为红色 */
        }
    </style>